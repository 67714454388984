.banner-content-section {
   /* padding: 120px;
    width: 110%;
    height: 48rem;
    background-image: url('../../../../assets/images/servicos-image/banner.jpg');
    box-shadow: inset 0 0 0 100vw rgba(193, 198, 200, 0.75);
    background-size: cover;
    background-position: right 0;
    margin-right: -1px;*/

    background-image: url('../../../../assets/images/servicos-image/banner.jpg');
    box-shadow: inset 0 0 0 100vw rgba(193, 198, 200, 0.75);
    background-size: cover;
    background-position: right 0;
    width: 100%;
}

.banner-content-section .banner-section {

}

.banner-content-text {
    padding: 120px;
    position: absolute;
    width: 100%;
    left: 13px;
    top: 83px;
    height: 459px;
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 1;
}

.banner-content-text .banner-content-title-text {
    font-family: 'Paytone One', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 26px;
    margin-bottom: 12px;
    color: #11111F;
    position: absolute;
    top: calc(50% - 233px / 2 - 108px);
    display: flex;
    left: 85px;
    align-items: center;
}

.banner-content-text .banner-content-subtitle-text {
    font-family: 'Paytone One', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    width: 60%;
    color: #11111F;
    position: absolute;
    left: 85px;
    top: 9rem;
    display: flex;
    align-items: center;

}

@media (max-width: 768px) {
    .banner-content-section {
        height: 360px;

        background-image: url('../../../../assets/images/servicos-image/banner.jpg');
        box-shadow: inset 0 0 0 100vw rgba(193, 198, 200, 0.75);
        background-size: cover;
        background-position: right 0;
        width: 100% !important;
    }

    .banner-content-text {
        top: 0;
        padding: 0;
        width: 90%;
        max-height: 360px;
    }

    .banner-content-text .banner-content-title-text {
        top: 40px;
        font-size: 24px;
        left: 0;
    }
    

    .banner-content-text .banner-content-subtitle-text {
        font-size: 16px;
        line-height: 17px;
        left: 0;
        width: 100%;
        top: 110px;
    }
}

@media (min-width: 1024px) {
    .banner-content-section {
        padding: 0 80px 67px 80px;
        height:  100%;
        width:   103%;

        background-image: url('../../../../assets/images/servicos-image/banner.jpg');
        box-shadow: inset 0 0 0 100vw rgba(193, 198, 200, 0.75);
        background-size:     100% 100%;
        background-position: bottom 0;
        background-repeat:   no-repeat;

        font-size: 16px;

        margin-top: 0;

        /*border: 1px solid red;*/
    }

    .banner-content-section .banner-section {
        padding:   200px 200px 200px 300px;
        font-size: 1em;

        /*border: 1px solid blue;*/
    }

    .banner-content-section .banner-section .banner-content-text {
        margin-left: 55px;
        margin-top:  50px;

        top: 5px;
        /*border: 1px solid green;*/
    }

    .banner-content-section .banner-section .banner-content-text .banner-content-title-text {
     }

     .banner-content-section .banner-section .banner-content-text .banner-content-subtitle-text {
        font-size: 22px;
        line-height: 22px;
        width:       60%;

        /*border: 1px solid yellow;*/
     }
}

@media (min-width: 1280px) {
    .banner-content-section {
        padding: 110px 80px 67px 80px;

        background-image: url('../../../../assets/images/servicos-image/banner.jpg');
        box-shadow: inset 0 0 0 100vw rgba(193, 198, 200, 0.75);
        background-size:     100% 100%;
        background-position: bottom 0;
        background-repeat: repeat;
    }

    .banner-content-section .banner-section .banner-content-text .banner-content-title-text {
        line-height: 200px;
     }

    .banner-content-section .banner-section .banner-content-text .banner-content-subtitle-text {
        line-height: 30px;
    }
}

@media (min-width: 1440px) {
    .banner-content-section {
        padding: 110px 80px 100px 80px;

        background-image: url('../../../../assets/images/servicos-image/banner.jpg');
        box-shadow: inset 0 0 0 100vw rgba(193, 198, 200, 0.75);
        background-size:     100% 100%;
        background-position: bottom 0;
        background-repeat: repeat;
    }
}