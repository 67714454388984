.apresentacao-content-container {
    background: var(--bg-black-componentes);
    padding: 13px 20px 20px 20px;
}

.apresentacao-content {
}

.apresentacao-content .apresentacao-content-text-div {
    margin-right: 11.6rem;
    width: 54.1rem;
    height: 100%;
    flex-shrink: 0;
    margin-bottom: 4rem;
}

.apresentacao-content .apresentacao-content-text-div .apresentacao-content-text-title {
    margin: 0 0 12px 0;
    text-align: left;
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
    color: #f8f8ff;
    font-family: 'Paytone One', sans-serif;
    white-space: nowrap;
}

.apresentacao-content .apresentacao-content-text-div .apresentacao-content-text-descricao-div {
    display: flex;
    flex-direction: column;
    position: absolute;
}

.apresentacao-content .apresentacao-content-text-div .apresentacao-content-text-descricao {
    display: flex;
    font-size: var(--font-size-mobile) !important;
    font-weight: 300;
    color: #f8f8ff;
    font-family: 'Paytone One', sans-serif;
    text-align: left;
    margin-bottom: 5px;
}

.apresentacao-content .apresentacao-content-text-div .apresentacao-content-botao-servicos {
    width: 210px;
    height: 35px;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.5px;
    color: #11111F;
    font-family: 'Paytone One', sans-serif;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    border: solid 0.2rem #C1C6C8;
    box-sizing: border-box;
    background-color: #C1C6C8;
    border-radius: 0.8rem;

    margin-top: 5px;
}

@media (max-width: 800px) {
    .apresentacao-content-img-div { display: none; }

    .apresentacao-content-container {
        height: 100% !important;
        padding: 20px;
        width: 100%;
    }

    .apresentacao-content {
        padding-bottom: 43px;
    }

    .apresentacao-content .apresentacao-content-text-div {
        width: 100% !important;
        margin: 0;
    }

    .apresentacao-content .apresentacao-content-text-div .apresentacao-content-text-title {
        margin-top: 21px;
        margin-bottom: 25px;
    }

    .apresentacao-content .apresentacao-content-text-div .apresentacao-content-text-descricao-div {  
        position: relative;
        margin: 0px;
    }


    .apresentacao-content .apresentacao-content-text-div  .apresentacao-content-text-descricao-div .apresentacao-content-text-descricao {
        width: 100%;
        line-height: 16.5px;
        font-size: 14px !important;
    }

    .apresentacao-content .apresentacao-content-text-div .apresentacao-content-botao-servicos {
        margin-top: 25px;
    }
}

@media (min-width: 1024px) {
    .apresentacao-content-container {
        font-size: 16px;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 34px 4.75em 5px 4.75em;
        background: #11111F;
    }

    .apresentacao-content {
        display: flex;
        justify-content: space-between;
        font-size: 1em;
        /*border:          1px solid green;*/
    }


    .apresentacao-content .apresentacao-content-text-div {
        width: minmax(513px, 600px);
        font-size: 1em;
    }


    .apresentacao-content .apresentacao-content-text-div .apresentacao-content-text-descricao-div {
        position: relative;
        left: 0;
        width: 100%;
        height: 100%;
        /*border:   1px solid tomato; */
    }


    .apresentacao-content .apresentacao-content-text-div .apresentacao-content-text-descricao {
        font-size: 0.8125em;
    }

    .apresentacao-content-container
     .apresentacao-content .apresentacao-content-text-div {
        position: relative;
        /*border: 1px solid pink;*/
        width: 50%;

    }


    .apresentacao-content .apresentacao-content-text-div .apresentacao-content-text-title {
        font-size: 1.125em;
    }

    .apresentacao-content-text-div .apresentacao-content-text-descricao {
        position: relative;
        /*border: 1px solid yellow;*/
        width: 100%;
    }

    .apresentacao-content-img-div {
        width: 45%;
        min-height: 100%;
        /*border:  1px solid blue;*/
    }

    .apresentacao-content-img {
        /*border: 1px solid orange; */
        width: 100%;
        height: 260px;
        width: minmax(500px, 600px);
        margin-top: 10px;
        background-size: 100% 112.5%;
        background-position: 0 -2.4rem;
        background-image: url('../../../../assets/images/image-bg-service.png');
        border-radius: 0.8rem;
    }

    .apresentacao-content-botao-servicos {
        padding: 1.25em;
    }
}

@media (max-width: 1024px) {
    .apresentacao-content-img {
        height: 230px;
        margin-top: 15px;
    }


    .apresentacao-content .apresentacao-content-text-div .apresentacao-content-text-descricao {
        font-size: 20px;
    }

    .apresentacao-content-container
     .apresentacao-content .apresentacao-content-text-div {
        width: 45%;
    }
}

@media (min-width: 1280px) {

    .apresentacao-content .apresentacao-content-text-div .apresentacao-content-text-descricao {
        font-size: 20px !important;
    }
}

@media (min-width: 1440px) {
    .apresentacao-content-container {
        padding: 80px 60px 60px 60px;
    }


    .apresentacao-content .apresentacao-content-text-div .apresentacao-content-text-title {
        font-size: 36px;
    }

    .apresentacao-content .apresentacao-content-text-div .apresentacao-content-text-descricao {
        margin-top: 24px;
        margin-bottom: 24px;
        font-size: 40px;
    }

    /*.apresentacao-section { background: red; }*/

    .apresentacao-content-img {
        /*border: 1px solid orange; */
        width: 100%;
        height: 400px;

        border-radius: 5px;
    }

    
    .apresentacao-content .apresentacao-content-text-div .apresentacao-content-botao-servicos {
        font-size: 22px;
        width: 360px;
        margin-top: 50px;
    }
}