.consultoria-tecnica-content-section {
    padding-left: 10px;
    padding-right: 10px;
}

.consultoria-tecnica-content-section .consultoria-tecnica-content-text
.consultoria-tecnica-content-text-title {
    font-family: 'Paytone One', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;

    color: #11111F;
}

.consultoria-tecnica-content-section .servicos-content-text
.consultoria-tecnica-content-text-descricao-div {
    margin-top: 9px;
}

.consultoria-tecnica-content-section .consultoria-tecnica-content-text
.consultoria-tecnica-content-text-descricao-div .consultoria-tecnica-content-text-descricao {
    font-family: 'Paytone One', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 13px !important;
    margin-bottom: 5px;
}

.consultoria-tecnica-content-section .consultoria-tecnica-content-text
.consultoria-tecnica-content-text-descricao-div .consultoria-tecnica-content-botao-encontre-solucoes-para-sua-empresa {
    width: 255px;
    height: 35px;
    cursor: pointer;
    display: flex;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.4736842105;
    color: #11111F;
    font-family: 'Paytone One', sans-serif;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    border: solid 0.2rem #C1C6C8;
    box-sizing: border-box;
    background-color: #C1C6C8;
    border-radius: 0.8rem;

    margin-top: 10px;
    margin-bottom: 5px;
}

@media (min-width: 1280px) {
    .consultoria-tecnica-content-section .consultoria-tecnica-content-text
    .consultoria-tecnica-content-text-descricao-div .consultoria-tecnica-content-text-descricao {
        font-size: 16px !important;
        line-height: 21px;
        margin-bottom: 13px;
    }

    .consultoria-tecnica-content-section .consultoria-tecnica-content-text
    .consultoria-tecnica-content-text-descricao-div .consultoria-tecnica-content-botao-encontre-solucoes-para-sua-empresa {
        width: 290px;
        font-size: 14px;
        height: 40px;
    }
}

@media (min-width: 1024px) {
    .space {
        width: 85px; /*border: 1px solid black;*/
    }

    .consultoria-tecnica-content-section {
        padding: 40px 30px 0px 30px;
    }

    .consultoria-tecnica-content-section .consultoria-tecnica-content {
        display: flex;

        /*border: 1px solid blue;*/
    }

    .consultoria-tecnica-content-section .consultoria-tecnica-content-img-div {
        padding: 50px;
        width: 550px;

        /*border: 1px solid red;*/
    }

    .consultoria-tecnica-content-section .consultoria-tecnica-content-img-div .consultoria-tecnica-content-img {
        position: relative;
        top: -50px;
        left: 50px;
        border-radius: 5px;

        background-image: url('../../../../assets/images/servicos-image/servicos-consultoria-tecnica.svg');
        box-shadow: inset 0 0 0 100vw rgba(55, 65, 79, 0.80);

        background-size: cover;
        background-position: right 0px;
        margin-right: -1px;

        padding: 130px;
    }

    .consultoria-tecnica-content-section .consultoria-tecnica-content-text {
        width: 50%;
        /*border: 1px solid green;*/
    }

    .consultoria-tecnica-content-text-descricao-div .consultoria-tecnica-content-text-descricao {
        font-size: 20px;
    }
}

@media (max-width: 1280px) {
    .consultoria-tecnica-content-section .consultoria-tecnica-content-text
    .consultoria-tecnica-content-text-descricao-div .consultoria-tecnica-content-text-descricao {
        font-size: var(--font-size-desktop);
    }
}

@media (min-width: 1440px) {

    .space {
        display: none; /*border: 1px solid black;*/
    }

    .consultoria-tecnica-content-section {
        padding: 100px 100px 0 40px;
    }

    .consultoria-tecnica-content-section .consultoria-tecnica-content {
        display: flex;
        justify-content: space-between;

        /*border: 1px solid blue;*/
    }

    .consultoria-tecnica-content-section .consultoria-tecnica-content-img-div {
        padding: 50px;
        width: 550px;

        /*border: 1px solid red;*/
    }

    .consultoria-tecnica-content-section .consultoria-tecnica-content-img-div .consultoria-tecnica-content-img {
        position: relative;
        top: -80px;
        left: 50px;
        border-radius: 10px;

        background-image: url('../../../../assets/images/servicos-image/servicos-consultoria-tecnica.svg');
        box-shadow: inset 0 0 0 100vw rgba(55, 65, 79, 0.50);

        background-size: cover;
        background-position: right 0;
        margin-right: -1px;

        padding: 200px 330px 200px 330px;
    }

    .consultoria-tecnica-content-section .consultoria-tecnica-content-text {
        width: 50%;
        /*border: 1px solid green;*/
    }

    .consultoria-tecnica-content-section .consultoria-tecnica-content-text
    .consultoria-tecnica-content-text-title {
        font-size: 32px;
        margin-bottom: 40px;
    }


    .consultoria-tecnica-content-section .consultoria-tecnica-content-text
    .consultoria-tecnica-content-text-descricao-div .consultoria-tecnica-content-text-descricao {
        font-size: 24px;
        line-height: 32px;
        font-weight: 250;
        font-style: normal;
        margin-bottom: 25px;
        width: 80%;
    }

    .consultoria-tecnica-content-section .consultoria-tecnica-content-text
    .consultoria-tecnica-content-text-descricao-div .consultoria-tecnica-content-botao-encontre-solucoes-para-sua-empresa {
        margin-top: 40px;
        width: 400px;
        height: 50px;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 5px;
    }
}