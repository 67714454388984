* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

/* force scrollbar, not necessary, but may reduce gaps. Setting a max-height on .carousel also works */
html {
  overflow-y: scroll;
}

.flickity-button {
  display: none;
}

.carousel {
  min-height: 360px;
  width: 100vw;
  position: relative;
  padding: 0;
  outline: none;
}

.carousel .carousel {
  padding: 20px;
}

.carousel .flickity-viewport {
  height: 100%;
}

.carousel .flickity-viewport .flickity-slider .carousel {
  height: 100%;
}

.carousel .c1 {
}

.carousel .c2 {
  color: #fff;
  background: #1a4a5a;
}

.carousel .c3 {
  color: #fff;
  background: #0e2c40;
}

.carousel img {
  display: block;
  height: 400px;
}

/* CONTENT (TEXT) */
.carousel .carousel .slide-depoimento {
  position: absolute;
  top: 50px;
  font-family: Paytone One, sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  font-size: 19px;
  line-height: 20px;
  margin-right: 15px;
}

.carousel .carousel .slide-testemunha {
  position: relative;
  top: 40px;
  font-family: Paytone One, sans-serif;
  letter-spacing: 0;
  text-align: left;
  margin: 15px 0 20px 0;
  font-size: var(--font-size-mobile);
  line-height: 20px;
}



.carousel .carousel .testimony0 {
  position: absolute;
  top: 136px;
}

.carousel .carousel .testimony1 {
  position: absolute;
  top: 180px;
}

.carousel .carousel .testimony2 {
  position: absolute;
  top: 240px;
}

/* DOTS */
.flickity-page-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.flickity-page-dots .dot {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 5px;
}

.flickity-page-dots .dot.is-selected {
  background-color: #333;
}

.flickity-page-dots .dot.is-selected:after {
  display: none;
}

.flickity-page-dots .dot {
  color: transparent;
}

@media (max-width: 768px) {
  .flickity-page-dots {
    position: absolute;
    top: 330px;
    right: 0;
    left: 0;
  }
}

@media (min-width: 1024px) {
  .carousel .c2 {
    color: black;
    background: none;
  }

  .carousel .c3 {
    color: black;
    background: none;
  }
  /* CONTAINERS*/
  .carousel .carousel {
    padding: 0px 200px 0px 180px;
  }

  /* TEXT */
  .carousel .carousel .slide-depoimento {
    width: 80%;
    font-size: 30px !important;
    line-height: 30px;
  }

  /* BUTTONS (SETA) */
  .flickity-button {
    display: block;
  }

  .previous {
    position: absolute;
    z-index: 2;
    top: 140px;
    left: 76px;
  }

  .next {
    position: absolute;
    top: 140px;
    right: 76px;
  }

  .flickity-prev-next-button {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
  }

  .flickity-prev-next-button:hover {
    color: #555;
  }

  .flickity-prev-button::before,
  .flickity-next-button::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
  }

  .flickity-prev-button::before {
    transform: rotate(-135deg);
  }

  /* DOTS */
  .flickity-page-dots {
    position: absolute;
    bottom: 30px;
    left: 180px;
    z-index: 2;
  }
}