.desenvolvimento-de-aplicativos-content-section {
    padding-left: 10px;
    padding-right: 10px;
}

.desenvolvimento-de-aplicativos-content-section .desenvolvimento-de-aplicativos-content-text .desenvolvimento-de-aplicativos-content-text-title {
    font-family: 'Paytone One', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;

    color: #11111F;
}

.desenvolvimento-de-aplicativos-content-section .servicos-content-text .desenvolvimento-de-aplicativos-content-text-descricao-div {
    margin-top: 12px;
}

.desenvolvimento-de-aplicativos-content-section .desenvolvimento-de-aplicativos-content-text .desenvolvimento-de-aplicativos-content-text-descricao-div .desenvolvimento-de-aplicativos-content-text-descricao {
    font-family: 'Paytone One', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: var(--font-size-mobile);
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.desenvolvimento-de-aplicativos-content-section .desenvolvimento-de-aplicativos-content-text .desenvolvimento-de-aplicativos-content-text-descricao-div .desenvolvimento-de-aplicativos-content-botao-construa-seu-app {
    width: 199px;
    height: 35px;
    cursor: pointer;
    display: flex;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.4736842105;
    color: #11111F;
    font-family: 'Paytone One', sans-serif;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    border: solid 0.2rem #C1C6C8;
    box-sizing: border-box;
    background-color: #C1C6C8;
    border-radius: 0.8rem;

    margin-top: 10px;
    margin-bottom: 5px;
}

@media (max-width: 768px) {
    .desenvolvimento-de-aplicativos-content-section .desenvolvimento-de-aplicativos-content .desenvolvimento-de-aplicativos-content-img-div {
        display: none;
    }

    .desenvolvimento-de-aplicativos-content-section .desenvolvimento-de-aplicativos-content-text 
    .desenvolvimento-de-aplicativos-content-text-descricao-div .desenvolvimento-de-aplicativos-content-text-descricao {
        margin-bottom: 5px;
    }
}

@media (min-width: 1024px) {
    .space {
        width: 85px;
        /* border: 1px solid black; */
    }

    .desenvolvimento-de-aplicativos-content-section {
        padding: 40px 30px 0 30px;
    }

    .desenvolvimento-de-aplicativos-content-section .desenvolvimento-de-aplicativos-content {
        display: flex;

       /* border: 1px solid blue; */
    }

    .desenvolvimento-de-aplicativos-content-section .desenvolvimento-de-aplicativos-content .desenvolvimento-de-aplicativos-content-img-div {
        padding: 50px;
        width: 550px;

       /* border: 1px solid red; */
    }

    .desenvolvimento-de-aplicativos-content-section .desenvolvimento-de-aplicativos-content .desenvolvimento-de-aplicativos-content-img-div .desenvolvimento-de-aplicativos-content-img {
        position: relative;
        top: -12px;
        left: 50px;
        border-radius: 5px;

        background-image: url('../../../../assets/images/servicos-image/servicos-desenvolvimento-de-app.svg');
        box-shadow: inset 0 0 0 100vw rgba(55, 65, 79, 0.80);

        background-size: cover;
        background-position: right 0;
        margin-bottom: 20px;

        padding: 140px;
    }

    .desenvolvimento-de-aplicativos-content-section .desenvolvimento-de-aplicativos-content .desenvolvimento-de-aplicativos-content-text {
        width: 50%;
       /* border: 1px solid green; */
    }

    .desenvolvimento-de-aplicativos-content-section .desenvolvimento-de-aplicativos-content-text .desenvolvimento-de-aplicativos-content-text-descricao-div .desenvolvimento-de-aplicativos-content-botao-construa-seu-app {
        position: absolute;
        right: 40px;
        margin-top: 5px;
    }
}

@media (min-width: 1280px) {
    .desenvolvimento-de-aplicativos-content-section .desenvolvimento-de-aplicativos-content-text .desenvolvimento-de-aplicativos-content-text-descricao-div .desenvolvimento-de-aplicativos-content-text-descricao {
        font-size: var(--font-size-desktop);
        line-height: 21px;
        margin-bottom: 3px;
    }

    .desenvolvimento-de-aplicativos-content-section .desenvolvimento-de-aplicativos-content-text .desenvolvimento-de-aplicativos-content-text-descricao-div .desenvolvimento-de-aplicativos-content-botao-construa-seu-app {
        position: absolute;
        width: 250px;
        font-size: 18px;
        height: 40px;
        right: 40px;
        margin-top: 20px;
    }
}

@media (min-width: 1440px) {
    .desenvolvimento-de-aplicativos-content-section {
        padding: 40px 100px 40px 30px;
    }

    .space { display: block; }

    .desenvolvimento-de-aplicativos-content-section .desenvolvimento-de-aplicativos-content {
        justify-content: space-between;
    }

    .desenvolvimento-de-aplicativos-content-section .desenvolvimento-de-aplicativos-content .desenvolvimento-de-aplicativos-content-img-div {
        padding: 50px;
    }

    .desenvolvimento-de-aplicativos-content-section .desenvolvimento-de-aplicativos-content .desenvolvimento-de-aplicativos-content-img-div .desenvolvimento-de-aplicativos-content-img {
        position: relative;
        top: -12px;
        left: 50px;
        border-radius: 10px;

        background-image: url('../../../../assets/images/servicos-image/servicos-desenvolvimento-de-app.svg');
        box-shadow: inset 0 0 0 100vw rgba(55, 65, 79, 0.25);

        background-size: cover;
        background-position: right 0;
        margin-bottom: 20px;

        padding: 200px 330px 200px 330px;
    }

    .desenvolvimento-de-aplicativos-content-section .desenvolvimento-de-aplicativos-content-text .desenvolvimento-de-aplicativos-content-text-title {
        font-size: 32px;
        font-weight: 400;
        font-style: normal;
        line-height: 32px;
        font-family: 'Paytone One', sans-serif;
        color: #11111F;
        margin-bottom: 30px;
    }
    
    .desenvolvimento-de-aplicativos-content-section .desenvolvimento-de-aplicativos-content-text .desenvolvimento-de-aplicativos-content-text-descricao-div .desenvolvimento-de-aplicativos-content-text-descricao {
        font-family: 'Paytone One', sans-serif;
        color: #11111F;
        font-weight: 300;
        font-size: 20px;
        line-height: 28px;
        width: 82%;
        margin-bottom: 30px;
    }

    .desenvolvimento-de-aplicativos-content-section .desenvolvimento-de-aplicativos-content-text .desenvolvimento-de-aplicativos-content-text-descricao-div .desenvolvimento-de-aplicativos-content-botao-construa-seu-app {
        position: absolute;
        width: 400px;
        font-size: 20px;
        height: 50px;
        right: 140px;
        margin-top: 28px;
    }
}