.faleconosco-clients-section {
    width: 100%;
    font-size: var(--font-size-mobile);
    padding: 20px 40px 20px 40px;
    margin-bottom: 0;
    border-bottom: 1px solid gray;
}

.faleconosco-clients-section .faleconosco-clients-content {
    font-family: 'Paytone One', sans-serif;
    font-weight: 300;
    font-size: 1em;

    /*border: 1px solid red;*/
}

.faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text {
    width: 500px;
    font-size: 1em;

    /* border: 1px solid blue; */
}

.faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text .faleconosco-clients-text-title {
    font-family: 'Paytone One', sans-serif;
    font-weight: 400;
    font-size: 1.19em;
}

.faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text .faleconosco-clients-text-descricao {
    font-size: 0.8415em;
    margin-bottom: 6px;
    font-weight: normal;
}

.faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-img {
    /*border: 1px solid green; */
}

.faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-img .faleconosco-clients-img {}

@media (max-width: 800px) {
    .faleconosco-clients-section {
        padding: 21px 10px 21px 10px;
    }

    .faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text {
        width: 100%;
    }

    .faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text .faleconosco-clients-text-title {
        font-size: 1.19em;
    }

    .faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text .faleconosco-clients-text-descricao {
        font-size: 0.8415em;
        margin-bottom: 6px;
    }
}

/* LOGO CLIENTES */
.faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text .faleconosco-clients-text-clients {
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text .faleconosco-clients-text-clients 
.faleconosco-clients-arvetex {
    width: 90px;
    height: 100px;
    background: url("../../../../assets/images/parceiros/logo\ avertex.png");
    background-size: 100% 100%;
}

.faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text .faleconosco-clients-text-clients 
.faleconosco-clients-alquimia {
    width: 180px;
    height: 100px;
    background: url("../../../../assets/images/parceiros/Alquimia\ do\ cheff.png");
    background-size: 100% 100%;
}

.faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text .faleconosco-clients-text-clients 
.faleconosco-clients-uniconsult {
    width: 180px;
    height: 100px;
    background: url("../../../../assets/images/parceiros/Logo\ Uniconsult\ SF.png");
    background-size: 100% 100%;
    margin-left: -60px;

}

@media(max-width: 768px) {
    .faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text .faleconosco-clients-text-clients {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }

    .faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text .faleconosco-clients-text-clients 
    .faleconosco-clients-arvetex {
        height: 70px;
        width: 65px;
    }
    
    .faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text .faleconosco-clients-text-clients 
    .faleconosco-clients-alquimia {

    }
    
    .faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text .faleconosco-clients-text-clients 
    .faleconosco-clients-uniconsult {
        width: 200px;
        margin-left: -70px;
    }
}

@media (min-width: 1024px) {
    .faleconosco-clients-section {
        padding: 60px 40px 60px 40px;
    }

    .faleconosco-clients-section .faleconosco-clients-content {
        display: flex;
        justify-content: space-around;
    }

    .faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-img {
        margin-left: 0;

        width: 350px;
        height: 220px;
        background: url("../../../../assets/images/contactUs-image/nexus.jpg");
        background-size: 100% 100%;

        /* border: 1px solid pink; */
    }
}

@media (min-width: 1280px) {
    .faleconosco-clients-section {
        padding: 60px 65px 60px 40px;
    }

    .faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text {
        width: 660px;
    }

    .faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text .faleconosco-clients-text-title {
        font-size: 30px;
        margin-bottom: 30px;
    }

    .faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text .faleconosco-clients-text-descricao {
        font-size: 22px;
        margin-bottom: 30px;
    }

    .faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-img {
        width: 400px;
        height: 280px;
        margin-right: 35px;
    }
}

@media (min-width: 1440px) {
    .faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text {
        width: 800px;
    }

    .faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text .faleconosco-clients-text-title {
        font-size: 32px;
    }

    .faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text .faleconosco-clients-text-descricao {
        font-size: 18px;
        font-weight: normal;
        margin-bottom: 25px;
        line-height: 24px;
    }

    .faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-img {
        width: 600px;
        margin-left: 35px;
    }

    .faleconosco-clients-section .faleconosco-clients-content .faleconosco-clients-content-text .faleconosco-clients-text-clients {
        display: flex;
        align-items: center;
        margin-top: 45px;
    }
}