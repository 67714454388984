.faleconosco-section {
    font-family: 'Paytone One', sans-serif;
}

.faleconosco-section .faleconosco-content-text-title {
    font-size:     22px;
    font-weight:    400;
    line-height:   32px;
    letter-spacing: 0;
    text-align:    left;
}

.faleconosco-section .faleconosco-content-text-descricao-div {
    position: relative;
    left:         22px;
    width:         90%;
}

.faleconosco-section .faleconosco-content-text-descricao-div .faleconosco-content-text-descricao {
    font-family: 'Paytone One', sans-serif;
    font-size:     var(--font-size-mobile);
    font-weight:    300;
    letter-spacing: 0;
    text-align:    left;
    color:    #11111F;
    margin-bottom: 8px;
}

.faleconosco-section .faleconosco-content-text-descricao-div .faleconosco-content-botao-agendamento {
    width:       180px; 
    height:      35px;
    cursor:      pointer;
    display:     flex;
    font-size:   13px;
    font-weight: 500;
    line-height: 1.4736842105;
    color: #11111F;
    font-family:     'Paytone One', sans-serif;
    white-space:     nowrap;
    align-items:     center;
    justify-content: center;
    border:     solid 0.2rem var(--bg-gray-buttons);;
    box-sizing: border-box;
    background-color: var(--bg-gray-buttons);;
    border-radius:      0.8rem;
    margin-top:         5px;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .faleconosco-section {
        padding: 20px;
    }

    .faleconosco-section .faleconosco-content {
        padding-bottom: 43px;
    }

    .faleconosco-section .faleconosco-content .faleconosco-content-text-descricao-div {
        margin: 0;
        left: 0;
        width: 100%;
    }

    .faleconosco-section .faleconosco-content-text-title {
        font-size: 23px;
        margin-top: 21px;
        margin-bottom: 25px;
    }

    .faleconosco-section .faleconosco-content .faleconosco-content-text-descricao-div .faleconosco-content-text-descricao {
        font-size: 14px;
        line-height: 16.5px;
    }

    .faleconosco-section .faleconosco-content-text-descricao-div .faleconosco-content-botao-agendamento {
        width: 220px;
        height: 37px;
        font-size:   14px;
        line-height: 16.5px;
        margin-top: 25px;
    }

    .faleconosco-section .faleconosco-content .faleconosco-content-img-div {
        display: none;
    }
}

@media (min-width: 1024px) {
    .faleconosco-section {
       /* border: 1px solid green;*/
        padding: 40px 70px 40px 70px;
        font-size: 16px;
    }

    .faleconosco-section .faleconosco-content {
        font-size:       1em;
        display:         flex;
        margin-top:      -10px;

        /*border: red 1px solid;*/
    }

    .faleconosco-section .faleconosco-content .faleconosco-content-text-descricao-div {
        font-size: 1em;
        position:  relative;
        left:      0;
        top: 15px;
        width:     60%;

       /* border:   1px solid black; */
    }

    .faleconosco-section .faleconosco-content .faleconosco-content-text-descricao-div .faleconosco-content-text-title {
        font-size: 32px;
    }

    .faleconosco-section .faleconosco-content .faleconosco-content-text-descricao-div .faleconosco-content-text-descricao {
        font-size: 24px;
        line-height:   24px;
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .faleconosco-section .faleconosco-content .faleconosco-content-img-div {
        font-size: 1em;
        width:     31.25em;
        /*border:    1px solid blue;*/
    }

    .faleconosco-section .faleconosco-content .faleconosco-content-img-div .faleconosco-content-img {
        padding: 100px 100px 150px 100px;
        width: 100%;
        height: auto;
        background: url('../../../../assets/images/image.svg') no-repeat center/cover;
        border-radius: 5px;
       /* border: 1px solid orange;*/
    }
}

@media (min-width: 1280px) {
    .faleconosco-section .faleconosco-content .faleconosco-content-img-div .faleconosco-content-img {
        height: 90%;
        width:  100%;
        background-size:     100% 100%;
        background: url('../../../../assets/images/image.svg') no-repeat;
        /* border: 1px solid orange; */
    }

    .faleconosco-section .faleconosco-content .faleconosco-content-text-descricao-div .faleconosco-content-text-title {
        font-size: 32px;
    }

    .faleconosco-section .faleconosco-content .faleconosco-content-text-descricao-div .faleconosco-content-text-descricao {
        font-size: 20px;
        line-height:   24px;
        margin-bottom: 30px;
        margin-top: 30px;
    }
}

@media (min-width: 1440px) {

    .space {  width:10px;}
    .faleconosco-section { padding: 80px 60px 50px 60px; }

    .faleconosco-section .faleconosco-content .faleconosco-content-text-descricao-div .faleconosco-content-text-title {
        font-size: 36px;
        margin-bottom: 40px;
    }

    .faleconosco-section .faleconosco-content .faleconosco-content-text-descricao-div .faleconosco-content-text-descricao {
        font-size: 20px;
        line-height:   24px;
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .faleconosco-section .faleconosco-content .faleconosco-content-img-div .faleconosco-content-img {
        /*border: 1px solid orange; */
        width: 600px;
        height: 450px;

        border-radius: 5px;
    }

    .faleconosco-section .faleconosco-content-text-descricao-div .faleconosco-content-botao-agendamento {
        font-size: 20px;
        height: 60px;
        width: 360px;
        margin-top: 80px;
    }

    .faleconosco-section .faleconosco-content { justify-content: space-between;}
    
    .faleconosco-section .faleconosco-content .faleconosco-content-img-div .faleconosco-content-img { width: 600px;}
    .faleconosco-section .faleconosco-content .faleconosco-content-text-descricao-div {  min-width: 400px; justify-self: flex-start;}
}