.desenvolvimento-de-sistemas-section {
    box-sizing: border-box;
    width: 100%;
    height: 380px;
    background: var(--bg-black-componentes);
    padding: 13px;
}

.desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content {
}

.desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content .desenvolvimento-de-sistemas-text-title {
    color: #F8F8FF;
    font-size: 19px;
    font-family: 'Paytone One', sans-serif;
    font-weight: 300;
    line-height: 32px;
}

.desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content .desenvolvimento-de-sistemas-text-div
.desenvolvimento-de-sistemas-content-botao-obter-site-personalizado {
    width: 290px;
    height: 35px;
    cursor: pointer;
    display: flex;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.4736842105;
    color: #11111F;
    font-family: 'Paytone One', sans-serif;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    border: solid 0.2rem #C1C6C8;
    box-sizing: border-box;
    background-color: #C1C6C8;
    border-radius: 0.8rem;

    margin-top: 10px;
    margin-bottom: 5px;
}

.desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content .desenvolvimento-de-sistemas-text-descricao {
    font-family: 'Paytone One', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    margin-bottom: 5px;

    color: #F8F8FF;
}

.desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content
.desenvolvimento-de-sistemas-text-descricao .desenvolvimento-de-sistemas-content-text-descricao {
    margin-bottom: 5px;
}

@media (max-width: 800px) {
    .desenvolvimento-de-sistemas-section {
        height: 100%;
    }
}

@media (min-width: 1024px) {

    .servico-space {
        margin-left: 40px;
    }

    .desenvolvimento-de-sistemas-section {
        padding: 50px 60px 90px 60px;
        height: 430px;
    }

    .desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content {
        display: flex;

        /* border: 1px solid green; */
    }

    .desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content .desenvolvimento-de-sistemas-text-div {
        /*border: 1px solid yellow;*/
        width: 200%;
        height: 100%;

        position: relative;
    }

    .desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content .desenvolvimento-de-sistemas-text-title {
        font-size: 32px;
        line-height: 32px;
        font-weight: 400;
    }

    .desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content .desenvolvimento-de-sistemas-text-div
    .desenvolvimento-de-sistemas-content-botao-obter-site-personalizado {
        margin-top: 32px;
    }

    .desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content .desenvolvimento-de-sistemas-text-descricao {
        margin-bottom: 10px;
    }

    .desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content
    .desenvolvimento-de-sistemas-content-img-div {
        margin-left: 0;

        /* padding: 20px 30px 40px 500px; */
        height: 200px;
        width: 400px;

        /*border: 1px solid red;*/
    }

    .desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content
    .desenvolvimento-de-sistemas-content-img {
        border-radius: 5px;
        height: 270px;
        width: 430px;

        background-image: url('../../../../assets/images/servicos-image/servicos-desenvolvimento-sistemas-web.png');
        box-shadow: inset 0 0 0 100vw rgba(55, 65, 79, 0.2);

        background-size: cover;
        background-position: right 0;

        padding: 10px;
        position: relative;
        right: 0;
        /* border: 1px solid blue; */
    }
}

@media (min-width: 1280px) {
    .servico-space {
        margin-left: 0;
    }

    .desenvolvimento-de-sistemas-section {
        padding: 50px 65px 95px 60px;
        height: 430px;
    }

    .desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content .desenvolvimento-de-sistemas-text-descricao {
        font-size: var(--font-size-desktop);
        margin-bottom: 20px;
    }

    .desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content .desenvolvimento-de-sistemas-text-div {
        width: 50%;
        height: 120%;
    }

    .desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content
    .desenvolvimento-de-sistemas-content-img-div {
        width: 600px;
    }

    .desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content
    .desenvolvimento-de-sistemas-content-img {
        width: 500px;
        right: -80px;
    }
}

@media (min-width: 1440px) {
    .desenvolvimento-de-sistemas-section {
        padding: 50px 80px 65px 100px;
        height: 500px;
    }

    .desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content .desenvolvimento-de-sistemas-text-title {
        font-size: 32px;
        font-weight: 400;
        font-style: normal;
        line-height: 32px;
        margin-bottom: 50px;
    }

    .desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content
    .desenvolvimento-de-sistemas-text-descricao .desenvolvimento-de-sistemas-content-text-descricao {
        width: 85%;
        font-size: 20px;
        font-weight: 300;
        font-style: normal;
        line-height: 24px;
        margin-bottom: 30px;
    }

    .desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content .desenvolvimento-de-sistemas-text-div
    .desenvolvimento-de-sistemas-content-botao-obter-site-personalizado {
        width: 420px;
        height: 50px;
        font-size: 18px;
        line-height: 16px;
        font-weight: 400;
        font-style: normal;
    }

    .desenvolvimento-de-sistemas-section .desenvolvimento-de-sistemas-content
    .desenvolvimento-de-sistemas-content-img {
        width: 700px;
        height: 350px;
        right: -80px;
        border-radius: 10px;
    }
}