.App-footer {
    width: 100%;
    bottom: 0;
    background: var(--bg-black-componentes);
    padding: 0 40px 0 0;
    text-align: center;
    color: #F8F8FF;
}

.App-footer .footer-bcb-div .footer-content-div .footer-icon-div .footer-icon-bcb {
    width: 230px;
    height: 183px;
}

.icon-instagram {
    width:  3rem;
    height: 3rem;
    margin-left: 20px;
    background-size: 100% 100%;
    background-image: url('../../assets/images/footer-image/icon-instagram.svg');
}

.icon-linkedin {
    width:  3rem;
    height: 3rem;
    background-size: 100% 100%;
    margin-left: 15px;
    background-image: url('../../assets/images/footer-image/icon-linkedin.svg');
}

.icon-C {
    /*width:  6rem;
    height: 6rem;
    background-size: 100% 100%;
    background-image: url('../../assets/images/footer-image/icon-C.svg');*/
}

.icon-localizacao {
  /*  background-size: 100% 100%;
    background-repeat: no-repeat;
    background: url('../../assets/images/footer-image/'); */
}

.footer-bcb-div {}

.footer-bcb-div .footer-content-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
   /* border: 1px solid red; */
}

.footer-bcb-div .footer-content-div .footer-icon-div {
    display: flex;
}

.footer-bcb-div .footer-content-div .footer-icon-div .footer-text {
    /*border: 1px solid green; */
    text-align: left;
}

.footer-bcb-div .footer-content-div .footer-icon-div .footer-text .footer-text-title {
    font-family: 'Paytone One', sans-serif;
    font-weight: 400;
    font-size: 25px;
    margin-top: 15px;
}

.footer-bcb-div .footer-content-div .footer-icon-div .footer-text .footer-text-subtitle {
    font-family: 'Paytone One', sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 4px;
}

.footer-bcb-div .footer-content-div .footer-icon-div .footer-text .footer-text-endereco {
    margin-top: 60px;
    margin-bottom: 4px;
    color: #F8F8FF;
}

.footer-bcb-div .footer-content-div .footer-icon-div .footer-text .footer-text-endereco {
    font-family: 'Paytone One', sans-serif;
    font-weight: 200;
    font-size: 12px;
}

.footer-bcb-div .footer-content-div .footer-icon-div .footer-text .footer-text-infos {
    font-family: 'Paytone One', sans-serif;
    font-weight: 200;
    margin-bottom: 4px;
    color: #F8F8FF;
    font-size: 12px;
}

.footer-bcb-div .footer-content-div .footer-icon-div .footer-icon-bcb {
    width:  224px;
    height: 183px;
    background-size: 100% 100%;
    background-image: url('../../assets/images/logo-byg.png');
}

.footer-bcb-div .footer-content-div .footer-nav-links {
    display: flex;
    align-items: center;

   /* border: 1px solid green; */
}

.footer-bcb-div .footer-content-div .footer-nav-links .nav {
    display: flex; 
}

.footer-bcb-div .footer-content-div .footer-nav-links .nav .nav-link { 
    color: #F8F8FF;
    font-size: 14px;
    font-family: 'Paytone One', sans-serif;
    text-decoration: none;
    margin-right: 6px;

  /* border: 1px solid red; */
}

.footer-bcb-div .footer-content-div .footer-nav-links .icons { 
    display: flex; 
}

@media (max-width: 800px) {
    .App-footer { 
        position: relative; 
        bottom: 0;
        height: 190px;
        padding: 45px 5px 5px 5px;
    }

    .App-footer .footer-bcb-div .footer-content-div .footer-icon-div  .footer-icon-bcb { display: none; }

    .footer-bcb-div .footer-content-div { 
        width: 100%;
    }

    .footer-bcb-div .footer-content-div .footer-icon-div .footer-text .footer-text-endereco {
        margin-top: 10px;
        margin-bottom: 4px;
        color: #F8F8FF;
    }

    .footer-bcb-div .footer-content-div .footer-icon-div .footer-text .footer-text-endereco { margin-bottom: 10px; }

    .footer-bcb-div .footer-content-div .footer-icon-div .footer-text { height: 90%; width: 180px; }
    .footer-bcb-div .footer-content-div .footer-icon-div .footer-text .footer-text-title {
        font-family: 'Paytone One', sans-serif;
        font-weight: 400;
        font-size: 15px;
        margin-top: 0;
    }

    .footer-bcb-div .footer-content-div .footer-nav-links .nav .nav-link { 
        color: #F8F8FF;
        font-size: 11px;
        font-family: 'Paytone One', sans-serif;
        text-decoration: none;
        margin-right: 6px;
    
    }

    .footer-bcb-div .footer-content-div {
        height: 100px;
    }

    .icon-instagram, .icon-linkedin {
        margin-left: 6px;
    }
}

@media (min-width: 1024px) {
    .App-footer {
        padding: 5px 40px 20px 0;
    }

    
    .App-footer .footer-bcb-div .footer-content-div .footer-icon-div  .footer-icon-bcb { 
        display: block;
        width:  220px;
        height: 170px;
        margin-top: 34px;
    }
}

@media (min-width: 1440px) {
    .App-footer .footer-bcb-div .footer-content-div .footer-icon-div  .footer-icon-bcb { 
        width:  250px;
        height: 180px;
     }

     .App-footer { 
        padding: 20px 40px 40px 0;
     }
}