.App-header {
    box-sizing: border-box;
    padding: 5px;
    width: 100%;
    height: 128px;

    display: flex;
    align-items: center;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.0500000007);
    background: var(--bg-black-componentes);
}

.header-bcb-div {
    justify-content: space-between;
    display: flex;
    text-align: center;
    align-items: center;
    font-size: xxx-large;
    font-family: 'Paytone One', sans-serif;
}

.header-bcb-div .header-content-div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.header-bcb-div .header-content-div .header-icon-div {
    margin-right: 104.25rem;
    height: 100%;
    flex-shrink: 0;
}

.header-bcb-div .header-content-div .header-icon-div .icon-bcb {
    width: 200px;
    height: 120px;
    background-size: 100% 100%;
    background-image: url('../../assets/images/logo-byg.png');
    padding-bottom: 4px;
    padding-top: 4px;
    padding-left: 24px;
}

@media (max-width: 768px) {
    .App-header {
        height: 100px;
    }

    .header-bcb-div .header-content-div .header-icon-div .icon-bcb {
        width: 84px;
        height: 60px;
    }
}

@media (min-width: 1024px) {
    .App-header {
        height: 100px;
    }

    .header-bcb-div .header-content-div .header-icon-div .icon-bcb {
        width: 180px;
        height: 120px;
    }
}