* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --bg-black-componentes: #11111F;
  --bg-gray-buttons: #C1C6C8;

  --font-size-mobile: 13px;
  --font-size-desktop: 16px;
}

a { text-decoration: none; }

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;  /*remove o scroll mobile*/
}

.loading-logo-bygnexus {
  background-image: url('assets/images/byg-animation.gif'); 
  background-size: cover;
  background-position: center;

  width: 50%;
  padding: 100px 100px 150px 100px;
  height: 25%;
}

@media (min-width: 1024px) {
  .loading-logo-bygnexus {
    background-image: url('assets/images/byg-animation.gif'); 
    background-size: cover;
    background-position: center;
  
    margin-top: 25%;
    width: 430px;
    padding: 100px 100px 150px 100px;
    height: 25%;
  }
}

@media (max-width: 1980px) {
  html {
    font-size: 80%;
  }
}

@media (max-width: 1440px) {
  html {
    font-size: 60%;
  }
}
