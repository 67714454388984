.faleconosco-info-section {
    width: 100%;
    background: var(--bg-black-componentes);
    font-size: var(--font-size-mobile);
    padding: 20px 40px 20px 40px;
    margin-bottom: 0;
    border-bottom: 1px solid gray;
}

.faleconosco-info-section .faleconosco-info-content {
    font-family: 'Paytone One', sans-serif;
    color: #F8F8FF;
    font-weight: 300;
    font-size: 1em;

    /*border: 1px solid red;*/
}

.faleconosco-info-section .faleconosco-info-content .faleconosco-info-content-text {
    width: 500px;
    font-size: 1em;

   /* border: 1px solid blue; */
}

.faleconosco-info-section .faleconosco-info-content .faleconosco-info-content-text .faleconosco-info-text-title {
    font-family: 'Paytone One', sans-serif;
    color: #F8F8FF;
    font-weight: 400;
    font-size: 1.19em;
}

.faleconosco-info-section .faleconosco-info-content .faleconosco-info-content-text .faleconosco-info-text-descricao {
    font-size: 0.8415em;
    margin-bottom: 6px;
    font-weight: normal;
}

.faleconosco-info-section .faleconosco-info-content .faleconosco-info-content-img { /*border: 1px solid green; */}

.faleconosco-info-section .faleconosco-info-content .faleconosco-info-content-img .faleconosco-info-img {}

@media (max-width: 800px) {
    .faleconosco-info-section { padding: 21px 10px 21px 10px; margin-top: -78px; }

    .faleconosco-info-section .faleconosco-info-content .faleconosco-info-content-text { width: 100%; }

    .faleconosco-info-section .faleconosco-info-content .faleconosco-info-content-text .faleconosco-info-text-title { font-size: 1.19em; }

    .faleconosco-info-section .faleconosco-info-content .faleconosco-info-content-text .faleconosco-info-text-descricao {
        font-size: 0.8415em;
        margin-bottom: 6px;
    }   
}

@media (min-width: 1024px) {
    .faleconosco-info-section {
        padding: 60px 40px 60px 40px;
    }

    .faleconosco-info-section .faleconosco-info-content { 
        display: flex; 
        justify-content: space-between; 
    }

    .faleconosco-info-section .faleconosco-info-content .faleconosco-info-content-img {
        margin-left: 0;

        width:   400px;
        background: url("../../../../assets/images/contactUs-image/contactUs-image.svg");
        background-size: 100% 100%;

       /* border: 1px solid pink; */
    }
}

@media (min-width: 1280px) {
    .faleconosco-info-section .faleconosco-info-content .faleconosco-info-content-text { width: 660px; }

    .faleconosco-info-section .faleconosco-info-content .faleconosco-info-content-text .faleconosco-info-text-title {
        font-size: 32px;
        margin-bottom: 30px;
    }

    .faleconosco-info-section .faleconosco-info-content .faleconosco-info-content-text .faleconosco-info-text-descricao {
        font-size: 24px;
        margin-bottom:20px;
        line-height: 32px;
        font-weight: normal;
    }

    .faleconosco-info-section .faleconosco-info-content .faleconosco-info-content-img { width: 550px; margin-right: 35px; }
}

@media (min-width: 1440px) {
    .faleconosco-info-section .faleconosco-info-content .faleconosco-info-content-text { width: 850px; }

    .faleconosco-info-section .faleconosco-info-content .faleconosco-info-content-text .faleconosco-info-text-title {
        font-size: 36px;
        margin-bottom: 30px;
    }

    .faleconosco-info-section .faleconosco-info-content .faleconosco-info-content-text .faleconosco-info-text-descricao { 
        font-size: 20px;
        margin-bottom: 25px;
        line-height: 36px;
    }

    .faleconosco-info-section .faleconosco-info-content .faleconosco-info-content-img { width: 650px; margin-right: 60px; }
}