body {
    background: #F8F8FF;
}

/*.space {margin-left: 0px; display: none;} */

.lbl-email {
    margin-left: 10px;
}

.faleconosco-form-section {
    padding: 40px 40px 20px 20px;
    font-family: 'Paytone One', sans-serif;
    font-weight: 400;
    font-size: 16px;
    width: 100%;

    position: relative;
    top: -70px;

    /*border: 1px solid green;*/
}

.faleconosco-form-section .faleconosco-form-content {
    display: flex;
    justify-content: space-between;
    font-size: 1em;

    /*border: 1px solid red;*/
}

/* TEXT CONTAINER */

.faleconosco-form-section .faleconosco-form-content-text {
    font-size: 1em;
    width: 500px;

    /*border: 1px solid black;*/
}

.faleconosco-form-section .faleconosco-form-text-title {
    font-size: 24px;
    line-height: 32px;
}

.faleconosco-form-section .faleconosco-form-text-descricao {
    font-size: var(--font-size-mobile);
}

/* FORM CONTAINER */

.faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario {
    /*border: 1px solid blue;*/
}

.faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario .faleconosco-form-formulario-div {
    margin-bottom: 6px;
}

.faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario .faleconosco-form-formulario-botao-div {
    display: flex;
    justify-content: flex-end;
    /*border: 1px solid yellow;*/
}

.faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario .faleconosco-form-formulario-botao-div .faleconosco-form-formulario-botao {
    box-sizing: border-box;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    width: 120px;
    height: 35px;
    cursor: pointer;

    background: #C1C6C8;
    border: 2px solid #C1C6C8;
    border-radius: 8px;
}

.faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario .faleconosco-form-formulario-container {
    display: flex;
    justify-content: space-between;

    /*border: 1px solid yellow;*/
}

.faleconosco-form-formulario-label {
    display: flex;
    flex-direction: column;
    font-size: 0.8125em;
}

.faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario .faleconosco-form-formulario-input {
    box-sizing: border-box;

    width: 100%;
    height: 30px;

    border: 1px solid #C1C6C8;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-top: 3px;
    background: #F8F8FF;

    flex: none;
    order: 1;
    flex-grow: 0;
}

.faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario .faleconosco-form-formulario-textarea {
    box-sizing: border-box;

    width: 100%;
    height: 80px;

    border: 1px solid #C1C6C8;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    margin-top: 3px;
    border-radius: 8px;
    background: #F8F8FF;
}

@media (max-width: 800px) {
    .space {
        display: none;
    }

    .faleconosco-form-section {
        margin-top: 70px;
        padding: 21px 10px 27px 10px;
    }

    .faleconosco-form-section .faleconosco-form-content {
        flex-direction: column;
        padding: 0;
    }

    .faleconosco-form-section .faleconosco-form-content-text {
        width: 100%;
        margin-bottom: 20px;
    }

    .faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario .faleconosco-form-formulario-div {
        margin-bottom: 10px; 
    }

    .faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario .faleconosco-form-formulario-input {
        height: 40px;
        padding: 0 0 0 5px;
    }

    .faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario .faleconosco-form-formulario-textarea {
        height: 150px;
    }
}

@media (min-width: 1024px) {
    .faleconosco-form-section {
        top: 10px;
    }
}

@media (min-width: 1280px) {
    .lbl-email {
        margin: 0;
    }

    .faleconosco-form-section {
        padding: 40px 80px 40px 80px;
    }

    .faleconosco-form-section .faleconosco-form-content-text {
        width: 800px;
    }

    .faleconosco-form-section .faleconosco-form-text-descricao {
        font-size: 0.99em;
    }

    .faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario {
        min-width: 500px;
    }

    .faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario .faleconosco-form-formulario-div {
        margin-bottom: 12px;
    }

    .faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario .faleconosco-form-formulario-input {
        height: 35px;
    }

    .faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario .faleconosco-form-formulario-container .faleconosco-form-formulario-label {
        min-width: 238px;
    }

    .faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario .faleconosco-form-formulario-textarea {
        height: 130px;
    }
}

@media (min-width: 1440px) {
    .lbl-email {
        margin: 0;
    }

    .faleconosco-form-section {
        padding: 40px 80px 40px 80px;
    }

    .faleconosco-form-section .faleconosco-form-text-title {
        font-size: 32px;
        line-height: 32px;
        font-weight: normal;
        margin-bottom: 40px;
    }

    .faleconosco-form-section .faleconosco-form-content-text {
        width: 800px;
    }

    .faleconosco-form-section .faleconosco-form-text-descricao {
        font-size: 24px;
        line-height: 34px;
        font-weight: normal;
    }

    .faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario {
        min-width: 650px;
        font-size: 18px;
    }

    .faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario .faleconosco-form-formulario-div {
        margin-bottom: 12px;
    }

    .faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario .faleconosco-form-formulario-input {
        height: 50px;
        font-size: 18px;
        padding: 0 0 0 10px;
    }

    .faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario .faleconosco-form-formulario-container 
    .faleconosco-form-formulario-label {
        min-width: 295px;
    }

    .faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario .faleconosco-form-formulario-textarea {
        height: 200px;
        font-size: 18px;
        padding: 5px 0 0 10px;
    }

    .faleconosco-form-section .faleconosco-form-content .faleconosco-form-content-formulario .faleconosco-form-formulario-botao-div 
    .faleconosco-form-formulario-botao {
        box-sizing: border-box;
        font-weight: bold;
        font-size: 16px;
        position: relative;
        width: 150px;
        height: 45px;
        cursor: pointer;

        background: #C1C6C8;
        border: 2px solid #C1C6C8;
        border-radius: 8px;
    }
}