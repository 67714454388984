.home--home-banner-section {
    background-image: url('../../../../assets/images/home-bg-image.jpg');
    margin-right: -1px;
    box-sizing: border-box;
    width: 100%;
    height: 460px;
    padding: 20px;
    box-shadow: inset 0 0 0 100vw rgba(193, 198, 200, 0.65);
    background-size: cover;
    background-position: center;
    margin-bottom: 0;

    font-family: 'Paytone One';
    font-style: normal;
    font-weight: 400;
}


.home--home-banner-text {
    display: flex;
    flex-direction: column;
    align-items: end;

    height: 100%;
   /* background: red;*/
}

.home--home-banner-text-title {
    font-size: 72px;
    line-height: 65px;

    width: 60%;
    /*background: blue;*/
}

.home--home-banner-text-subtitle {
    font-size: 24px;
    line-height: 24px;

    width: 70%;
   /* background: yellow; */
}

@media (max-width: 768px) {
    .home--home-banner-section {
        height: 360px;
        box-shadow: inset 0 0 0 100vw rgba(193, 198, 200, 0.56);
    }

    .home--home-banner-text-title {
        font-size: 24px;
        line-height: 24px;
        text-align: end;

        width: 90%;
        margin-bottom: 40px;
        margin-top: 26px;
    }

    .home--home-banner-text-subtitle {
        font-size: 14px;
        line-height: 15px;
        text-align: end;

        width: 100%;
    }
}

@media (min-width: 1024px) {
    .home--home-banner-section {
        height: 570px;
    }

    .home--home-banner-text {
        margin-right: 60px;
    }

    .home--home-banner-text-title {
        font-size: 50px;
        margin-bottom: 40px;
        line-height: 45px;
        margin-top: 60px;
        text-align: end;
        width: 60%;
    }

    .home--home-banner-text-subtitle {
        text-align: end;
    }
}

@media (min-width: 1280px) {
    .home--home-banner-section {
        height: 535px;
    }

    .home--home-banner-text-title {
        margin-top: 76px;
        margin-bottom: 76px;
        font-size: 72px;
        line-height: 80px;
        width: 55%;
    }

    .home--home-banner-text-subtitle {
        font-size: 24px;
        line-height: 28px;
    }
}