/*section {
    margin-bottom: 1rem;
}*/

h2 {
    margin-bottom: 1rem;
}

main {
    overflow-x: hidden;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}


/* IMAGES */
@media (min-width: 1024px) {
    .whywechange-content-image {
        background-image: url('../../assets/images/byg-animation.gif'); 
        background-size: cover;
        background-position: center;

        padding: 50px 100px 150px 100px;
        width: 100%;
        height: auto;

        border-radius: 5px;
    }

}